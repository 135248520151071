import React from 'react'
import ReactModal from 'react-modal'
import './style.css'

interface TypeProps {
  isOpen: boolean;
  fecharModal: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkInput: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkInputFalse: any;
}

import regulamento from '../../assets/regulamento.pdf'

export function NewRegulamentoModal(props: TypeProps) {

  return (
    <>
      <ReactModal
        isOpen={props.isOpen}
        className="new_modal_regulamento"
        overlayClassName="overlay_modal_regulamento"
        onRequestClose={props.fecharModal}
      >
        <ol className='text_container'>
          <h1>Regulamento </h1>
          <h2>“Trocou, ganhou - Lubrax+ Benefício Porto Seguro”</h2>

          <li className='text_item'>
            Esta é uma ação promocional por prazo determinado e de participação facultativa na
            modalidade compre e ganhe, sem limitação de estoque de brindes, denominada “Trocou
            Ganhou – Lubrax+ Benefício Porto Seguro” (doravante denominada simplesmente “Ação”),
            sendo promovida pela Vibra Energia (Petrobras Distribuidora S.A.), inscrita no CNPJ/MF sob
            o número 34.274.233/0001-02, com sede na : Rua Correia Vasques, no 250 8o andar – Cidade
            Nova Rio de Janeiro - RJ, CEP 20.211-140, Município do Rio de Janeiro, Estado do Rio de
            Janeiro, Brasil (doravante denominada simplesmente “Promotora” ou “Petrobrás”).
          </li>

          <li className='text_item'>
            <u>Período de Participação:</u> De 23/05/22 a 01/07/25, exclusivamente nas unidades Lubrax+
            participantes nos postos Petrobras, para trocas de óleo utilizando produtos Lubrax, desde que
            atendidas todas as determinações do regulamento completo da Ação.
          </li>

          <li className='text_item'>
            Resta esclarecido que serão válidas apenas as trocas de óleo realizadas em veículos
            classificados como “automóveis”, ou seja, veículo automotor classificado como carro,
            destinado ao transporte de passageiros, com capacidade para até oito pessoas, com peso
            igual ou inferior a 3,5 toneladas. Não participam veículos como, por exemplo, mas não
            exclusivamente, motocicletas, caminhões, vans e ônibus. Poderão receber o benefício
            ofertado na Ação exclusivamente os automóveis em nome de pessoa física, veículos em nome
            de empresas (pessoa jurídica) não farão jus ao benefício. Para utilização do benefício ofertado
            será considerada sempre a placa do veículo que realizou a troca de óleo e o CPF do
            Participante cadastrado na Ação.
          </li>

          <li className='text_item'>
            A determinação dos ganhadores não está sujeita a qualquer tipo de sorteio, vale-brinde,
            concurso ou operação assemelhada. Esta Ação não se enquadra nas disposições da Lei no
            5.768/71 e suas respectivas regulamentações e, portanto, não está sujeita a autorização prévia
            do Ministério da Economia/SECAP.
          </li>

          <li className='text_item'>
            Participam da presente Ação exclusivamente as trocas de óleo lubrificante realizadas em
            automóveis nas unidades Lubrax+ participantes, com a utilização de óleo lubrificante Lubrax
            (doravante denominado simplesmente “Produtos Participantes” ou “Serviços Participantes”).
            Qualquer outro produto e/ou serviço que não esteja especificamente mencionado neste
            regulamento, não participa da Ação.
          </li>

          <li className='text_item'>
            A Ação tem participação voluntária, sendo destinada a qualquer pessoa física interessada,
            maior de 18 (dezoito) anos completos na data de sua participação, residente e domiciliado no
            Brasil, que esteja cadastrado no Programa de Fidelidade da rede de Postos Petrobras
            Premmia (doravante denominados simplesmente “Premmia”), que tenha realizado pelo menos
            01 (uma) troca de óleo com lubrificante Lubrax em alguma das lojas Lubrax+ participantes,
            desde que cumpridas todas as regras deste regulamento (doravante denominados
            simplesmente “Participantes”).
          </li>

          <li className='text_item'>
            Resta esclarecido que para participar nesta Ação, os interessados deverão estar cadastrados
            no Premmia, cuja inscrição é gratuita. O cadastro no Premmia deverá ser realizado no site
            <a href='www.petrobraspremmia.com.br'> www.petrobraspremmia.com.br</a> ou pelo aplicativo (“App”) Premmia.
          </li>

          <li className='text_item'>
            Considerando que o cadastro no Premmia é condição para participação na Ação, recomenda-
            se a observação das condições estabelecidas no regulamento do referido programa de 
            fidelidade, disponível para consulta no site <a href='www.petrobraspremmia.com.br'>www.petrobraspremmia.com.br</a>.
          </li>

          <li className='text_item'>
            Aqueles que já possuem cadastro no Premmia desde antes da vigência da Ação, estarão aptos
            a participar na forma deste Regulamento.
          </li>

          <li className='text_item'>
            Aqueles que ainda não forem cadastrados no Premmia no momento da participação na Ação
            terão até, no máximo, 03 (três) dias corridos a contar da data da realização da troca de óleo
            para realizar seu cadastro no Premmia. Caso o cadastro no Premmia não seja realizado dentro
            do prazo máximo estipulado o Participante perderá todo e qualquer direito ao benefício
            oferecido na Ação, mesmo que fique comprovada a troca de óleo realizada, visto que o
            cadastro no Premmia é condição necessária para participar.
          </li>

          <li className='text_item'>
            Os Participantes que cumprirem o disposto no regulamento completo da Ação receberão o
            benefício de 06 (seis) meses de Assistência Auto 24h Porto Seguro, a partir da data de ativação
            junto à Porto Seguro, limitado a utilização de, no máximo, 02 (duas) vezes durante a vigência
            (doravante denominado simplesmente “Brinde” ou “Benefício”), desde que cumpram o
            regulamento específico do serviço Assistência Auto 24hs Porto Seguro (Anexo I) e demais
            regras e condições impostas pela Porto Seguro.
          </li>

          <li className='text_item'>
            Realizada a troca de óleo na unidade participante Lubrax+, nas condições estabelecidas no
            regulamento completo da Ação, o participante deverá informar ao lubrificador (atendente do
            Lubrax+), todos os dados pessoais e do veículo solicitados, para cadastro no sistema de
            atendimento da franquia, o Lubrax+ System. Neste mesmo sistema, o participante deverá dar
            o aceite nos termos e condições de uso. Estes dados são necessários para comunicação com
            o participante e posterior ativação do benefício. Resta esclarecido que eventual utilização do
            Benefício será atrelada ao CPF + Placa do Veículo cadastrados.
          </li>

          <li className='text_item'>
            Caso não esteja cadastrado ainda no Premmia, o participante deverá cadastrar-se no Premmia
            dentro do prazo estabelecido neste regulamento.
          </li>

          <li className='text_item'>
            Realizados os passos acima com sucesso o Participante receberá por SMS e e-mail, no
            número de celular e e-mail informado em seu cadastro, um link para ativar o serviço Assistência
            Auto 24h Porto Seguro. Ao acessar o link será solicitado ao Participante que confirme os dados
            pessoais e do veículo, em especial a placa do veículo e CPF do Participante, visto que esta
            será a chave de acesso para eventual utilização do Benefício, e aceite os termos e condições
            de uso. O Participante terá no máximo 30 (trinta) dias corridos para ativar o serviço, caso
            contrário o link perderá sua validade e o Participante não terá direito ao benefício, mesmo que
            fique comprovada a troca de óleo realizada.
          </li>

          <li className='text_item'>
            Caso o participante identifique alguma inexatidão nas informações contidas em seu cadastro
            no Lubrax+ System, deverá entrar em contato através do telefone 4090 1337 (Capitais e
            Regiões Metropolitanas) ou 0800 770 1337 (Demais localidades), utilizando-se de provas
            documentais para a devida atualização e alteração dos dados. Cada solicitação será avaliada
            criteriosamente, mas não há garantia que todas serão aprovadas.
          </li>

          <li className='text_item'>
            O Benefício será considerado entregue uma vez enviado o SMS ou E-mail cadastrado pelo
            Participante. É de exclusiva responsabilidade de cada Participante a exatidão das informações
            fornecidas em seus cadastros, bem como a verificação constante de suas mensagens,
            sistemas que possam encaminhar mensagens para caixas antispam e demais ocorrências do
            tipo.
          </li>

          <li className='text_item'>
            Cada Participante poderá receber 01 (um) único Benefício a cada 06 (seis) meses para cada
            automóvel (carro) identificado por sua placa, ou seja, o benefício não é acumulativo para o
            mesmo carro. Cada Benefício ativado com sucesso permitirá ao usuário a utilização dos
            serviços ofertas, no máximo, 02 (duas) vezes durante sua vigência. Para utilização do
            Benefício os dados de CPF + Placa do Veículo deverão estar de acordo com o cadastrado em
            sistema.
          </li>

          <li className='text_item'>
            A obrigação da Promotora com os Participantes que fizerem jus aos Benefícios se encerrará
            com a entrega do Benefício através do link enviado por SMS ou E-mail informados no cadastro
            do Participante no sistema Lubrax+ System. A Promotora não oferece e não arcará com
            qualquer despesa por parte do Participante relacionada à Ação e/ou ao Benefício (Assistência
            Auto 24h Porto Seguro).
          </li>

          <li className='text_item'>
            Caso necessário usar o Benefício o contemplado deverá entrar em contato com a Assistência
            24hs Porto Seguro no telefone 0800 501 0060. Cada Benefício ativado com sucesso permitirá
            ao usuário a utilização dos serviços ofertas, no máximo, 02 (duas) vezes durante sua vigência.
          </li>

          <li className='text_item'>
            Os Participantes estarão sujeitos às garantias e condições usualmente ofertadas pelo
            fornecedor do Benefício (“Porto Seguro”) ao mercado consumidor, inclusive o aceite do
            regulamento e regras de utilização do Benefício. As condições gerais do Benefício oferecido
            na Ação poderão ser observadas através no Anexo I.
          </li>

          <li className='text_item'>
            A Promotora não terá qualquer responsabilidade acerca do tratamento dos dados informados
            pelo Participante ao fornecedor do Benefício (Assistência Auto 24h Porto Seguro), bem como
            o relacionamento entre Participante e tal fornecedor após a entrega do Benefício no formato
            aqui descrito.
          </li>

          <li className='text_item'>
            Os Benefícios oferecidos estarão atrelados às placas dos veículos e CPF do usuário
            informados em seu cadastro na Ação e não poderão ser trocados em momento algum, em seu
            todo ou em parte, por dinheiro ou qualquer outra coisa, bem como não deverão ser
            comercializados.
          </li>

          <li className='text_item'>
            Caso a Promotora encontre alguma ocorrência relacionada ao Benefício, como por exemplo,
            mas não exclusivamente, insatisfação com o fornecedor, poderá substituir o Benefício por
            outro similar ou encerrar a Ação antecipadamente sem prejuízo aos Participantes já
            contemplados.
          </li>

          <li className='text_item'>
            Os interessados, ao efetivarem suas participações, manifestam sua total concordância com as
            regras e o regulamento da Ação, o qual estará disponível no site
            <a href='www.postospetrobras.com.br/ganhe-mais-com/promocoes'>www.postospetrobras.com.br/ganhe-mais-com/promocoes</a>. O regulamento poderá ser alterado
            a qualquer momento pela Promotora e quaisquer alterações serão comunicadas
            imediatamente aos consumidores no site.
          </li>

          <li className='text_item'>
            Os Participantes concordam expressamente que a Promotora não será responsável por
            qualquer problema externo, de caso fortuito ou de força maior, que possa impossibilitar a
            participação em alguma das datas previstas, como, por exemplo, mas não exclusivamente,
            datas e horários das lojas participantes Lubrax+, disponibilidade de produtos nas lojas,
            fornecedor dos Benefícios, qualidade do serviço realizado, entre outras que aconteçam
            isolada ou conjuntamente. A Promotora se reserva o direito de, na eventualidade desta Ação
            não poder ocorrer por qualquer razão, adiá-la, modificá-la ou cancelá-la a fim de garantir a sua
            lisura e correção.
          </li>

          <li className='text_item'>
            O acesso à Internet é necessário para a realização do cadastro na Ação e no Premmia. Sua
            qualidade pode variar de acordo com o tipo de conexão, aparelho utilizado para acessar a
            Internet, provedor de Internet, região, entre outras variáveis como a disponibilidade
            momentânea da rede e/ou do site.
          </li>

          <li className='text_item'>
            Tendo em vista as características do ambiente da Internet, a Promotora não se
            responsabilizará pelos cadastramentos que não forem realizadas por problemas de conexão,
            de transmissão de dados, do servidor, das linhas telefônicas ou dos provedores de acesso dos
            usuários, por erros na leitura, ou ainda por falta de energia elétrica, caso fortuito ou força maior
            e/ou ações de terceiros, dos quais não possam exercer qualquer controle.
          </li>

          <li className='text_item'>
            Resta esclarecido que a Promotora, a qualquer tempo e ao seu exclusivo critério, poderá
            efetuar todo e qualquer tipo de ação preventiva ou corretiva para manter o bom funcionamento
            de qualquer ferramenta tecnológica relacionada à Ação.
          </li>

          <li className='text_item'>
            É terminantemente proibida a utilização de sistemas, softwares e outras ferramentas ou
            métodos automáticos, repetitivos ou programados, que criem condições de cadastramento,
            navegação ou participação, consideradas como práticas irregulares, desleais ou que atentem
            contra os objetivos desta Ação, casos em que, quando identificados, haverá a exclusão do
            Participante e de eventuais Benefícios atrelados à sua participação.
          </li>

          <li className='text_item'>
            As dúvidas, divergências, reclamações e esclarecimentos que não puderem ser solucionados
            pela interpretação do presente regulamento, bem como os casos omissos, serão resolvidos
            pela Promotora, cujas decisões serão finais e irrecorríveis.
          </li>

          <li className='text_item'>
            É terminantemente proibida a utilização de subterfúgios que criem condições de participação
            consideradas como práticas irregulares, desleais ou que atentem contra os objetivos desta
            Ação, casos em que, quando observados, haverá a exclusão imediata do Participante.
          </li>

          <li className='text_item'>
            Os Produtos Participantes da Ação serão comercializados pelo seu valor usual de mercado,
            não ensejando qualquer tipo de comercialização do Benefício e atendendo ao Art. 39 do
            Código de Defesa do Consumidor - Lei 8078/90.
          </li>

          <li className='text_item'>
            Os ganhadores autorizam, desde já, como consequência da conquista do Benefício, a
            utilização, pela Promotora e/ou suas agências, de seus nomes, imagens e sons de voz, em
            qualquer um dos meios escolhidos, para divulgação desta campanha, pelo período de 01 (um)
            ano, a partir do seu término sem qualquer tipo de pagamento ou ônus.
          </li>

          <li className='text_item'>
            A Ação poderá ser divulgada por meio de material de ponto de venda, mídias sociais, envio de
            e-mails promocionais ou qualquer outra forma que a Promotora julgar adequado, nos limites
            da lei.
          </li>

          <li className='text_item'>
            Quaisquer pontos não abordados neste regulamento serão dirimidos por uma Comissão
            Julgadora formada pela Promotora e suas agências. Suas decisões serão soberanas,
            irrevogáveis e irrecorríveis e levarão em conta a legislação pertinente.
          </li>

          <li className='text_item'>
            Fica, desde já, eleito o foro da comarca da cidade de São Paulo – SP para dirimir quaisquer
            controvérsias decorrentes deste Regulamento e Ação, com plena concordância de todos os
            Participantes e com exclusão expressa de qualquer outro, por mais privilegiado que seja ou
            venha a ser.
          </li>

          <p className='text_item'><a href={regulamento} target="_blank" rel="noreferrer">Clique aqui para ver o regulamento da Porto Seguro.</a></p>
        </ol>

        <div className='buttons'>
          <button
            className='button_confirmar'
            onClick={() => { props.checkInput(); props.fecharModal() }}
          >
            Confirmar
          </button>
          <button className='button_cancelar' onClick={() => { props.checkInputFalse(); props.fecharModal() }}>Cancelar</button>
        </div>


      </ReactModal>
    </>
  )
}