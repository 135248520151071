import React, { useState, useEffect, SetStateAction } from "react";

import ReactModal from "react-modal";

import { Content, HeaderModal, Container, Footer, ContainerFooter, Img, Button, Aviso } from "./styles";
import { SuccessImage } from "../../assets/SuccessImage";
import logoImg from '../../assets/logo_lubrax.png'
import logoPortoImg from '../../assets/logo_porto_seguro.png'
import logoPremmiaImg from '../../assets/logo_premmia.png'
import buttonImg from '../../assets/button.png'

const modalStyle = {
  overlay: {
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(0, 70, 0, 1)",
    border: "none",
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    position: "fixed" as "fixed",
    top:'0',
    bottom:'0',
    rigth:'0',
    left:'0',
    margin: "0 auto",
  },
  content: {
    border: "none",
    margin: "0 auto",
    padding: "0",
    width: "100%",
    boxSize: "border-box",
    height: "100%",
    background: "none",
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    textAlign: "center" as "center",
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    position: "absolute" as "absolute",
    top: "0",
    left: "0",
  }
}

type ErrorModalProps = {
  isOpen: boolean;
  closeModal: React.Dispatch<SetStateAction<boolean>>;
}

export function SuccessModal({ isOpen, closeModal }: ErrorModalProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    if(isOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [modalOpen]);

  const handleCloseModal = () => closeModal(false);

  return (
    <>
      <ReactModal
        isOpen={ true }
        style={ modalStyle }
        ariaHideApp={false}
      >
        <Container>
        <Button
          type="button"
          onClick={ handleCloseModal }
        >
          <div>
            <img src={buttonImg} alt="Botão de fechar o modal" />
          </div>

        </Button>
          <HeaderModal>
          <SuccessImage />
            <p>
              SUA INSCRIÇÃO FOI CONCLUÍDA<br />
              <span>COM SUCESSO!</span>
            </p>
          </HeaderModal>
          <Content>
            <div>
              <p>
                PARABÉNS!<br />
                AGORA* VOCÊ JÁ CONTA COM A <span>ASSISTÊNCIA AUTO PORTO SEGURO.</span>
              </p>
              <p style={{ fontWeight: 'normal', fontSize: '13px', marginTop: '2px' }}>
                *A ATUALIZAÇÃO DOS SISTEMAS PODE DEMORAR ATÉ 60 MINUTOS.
              </p>
            </div>

            <div>
              <Aviso>
                PELOS PRÓXIMOS 6 MESES VOCÊ TEM UMA SÉRIE DE SERVIÇOS PARA O SEU CARRO, 24 HORAS POR DIA, TODOS OS DIAS DA SEMANA.
              </Aviso>
  
              <ul>
                <li>Reboque até 100km.</li>
                <li>Carga de bateria.</li>
                <li>Chaveiro.</li>
                <li>Troca de pneus.</li>
              </ul>
            </div>

            <div>
              <p style={{ fontWeight: "normal", fontStyle: "normal"}}>
                Em caso de emergência, é só entrar em contato com a <strong>Porto Seguro</strong> pelo telefone <strong>0800 501 0060</strong> e informar o problema para iniciarem o atendimento. <span>Guarde este número.</span>
              </p>
            </div>
          </Content>
        <Footer>
          <ContainerFooter>
            <Img>
              <img src={logoImg} alt="" />
            </Img>
            <Img>
              <img src={logoPortoImg} alt="" />
            </Img>
            <Img>
              <img src={logoPremmiaImg} alt="" />
            </Img>
          </ContainerFooter>
          <p>Leia o regulamento completo em <a href="https://www.postospetrobras.com.br/ganhe-mais-com/promocoes">www.postospetrobras.com.br/ganhe-mais-com/promocoes</a>. Período de participação de 23/05/2022 a 01/07/2025.</p>
        </Footer>
        </Container>


      </ReactModal>

    </>
  );
}